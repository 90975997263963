<template>
  <div class="index-container">
    <div id="pdf" style="padding:20px;">
      欢迎来到心体汇运动健康管理平台
    </div>
  </div>
</template>

<script>
import htmlToPdf from '@/utils/htmlToPDF';
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    topdf()
    {
      htmlToPdf.downloadPDF(document.querySelector('#pdf'), '页面导出PDF的名字');
    }
  },
  mounted() {},
};
</script>
<style>
.el-select-dropdown__item {
  height: auto !important;
}
</style>
<style lang='scss' scoped>
.proItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .name {
  }
  .companys {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .company {
      font-size: 10px;
      line-height: 16px;
      color: silver;
      margin-bottom: 10px;
      font-weight: normal;
    }
  }
}
</style>
<style rel="stylesheet/scss" lang="scss" scoped>
/deep/.el-card__header {
  font-size: 14px;
  border-bottom: 0px;
}
/deep/.el-card__body {
  padding: 10px;
  padding-bottom: 0px;
  padding-top: 0px;
}
/deep/.el-tabs__item.is-active {
  color: #ee7031;
}
/deep/.el-tabs__item:hover {
  color: #ee7031;
}
/deep/.el-tabs__active-bar {
  background-color: #ee7031;
}
.index-container {
  height: 100%;
  font-size: 20px;
  .panelrow {
    display: grid;
    height: 49%;
    margin-bottom: 10px;
    grid-template-columns: 3fr 3fr 3fr;
    .card {
      margin: 5px;
      height: 100%;
    }
  }
  .panelrow2 {
    display: grid;
    height: 49%;
    margin-bottom: 10px;
    grid-template-columns: 1fr;
    .card {
      margin: 5px;
      height: 100%;
    }
  }
}
</style>